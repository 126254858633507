// Dashboard Notification Popup Css Starts Here
.notification-modal {
  .modal-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    padding: 10px !important;
  }
  .modal-content {
    border-radius: 15px;
    // width: 337px !important;
    width: 337px;
    padding: 30px 20px;
  }
  .modal-dialog {
    margin-right: 30px;
    margin-top: 90px;
  }
  .modal-body {
    .header {
      h6 {
        font-weight: 500;
        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .btns-time {
        cursor: pointer;
        border-radius: 50%;
        width: 25px;
        height: 25px;
      }
    }
    .notification-container {
      height: calc(100vh - 235px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        // width: 3px;
        display: none;
      }
    }
  }
}

.notification-popup {
  .notification-heading {
    /* Notifications */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
  }

  .cross-bg {
    background: #f3f3f3;
    border-radius: 14px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-notification-panel {
    height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }

    .main-seen-clear {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #F7F7F7;
      padding-bottom: 15px;
      margin-bottom: 15px;

      .main-seen-div {
        span {
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }
      }
      .main-clear-div {
        button {
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #989898;
          // margin-bottom: 0;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
  .notification-box {
    .unseen {
      background: $base-yellow;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .seen {
      background: white;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .order-status {
      /* Order Completed */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
    }
    .order-notif {
      /* Order #23456 has been delivered successfully */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #707581;
      margin-top: 4px;
    }
    .notif-delivery-time {
      /* 4 hours ago */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #707581;
      margin-top: 6px;
    }

    hr {
      width: 100%;
      height: 1px;
      background: #f6f6f6;
      margin: 0;
      margin: 15px 0;
    }
  }

  .view-all {
    /* View All (20) */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $base-yellow;
  }
}

// Dashboard Notification Popup Css Ends Here
