.myprofile-popup {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 14px;
}

.profile-modal {

  .modal-content {
    width: 337px;
    height: 380px;
    background: white;
    padding: 30px 20px;
  }
  .modal-dialog{
    // position: fixed;
    // top: 40px;
    // right:200px;
    // margin-right: 0;
    // margin-right: 0;
    // margin-top: 10px;
    // margin-right: -200px;
    position: fixed;
    right:380px;
    top:40px;
  }

  .modal-title {
    /* My Profile */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
  }

  .cross-bg {
    background: #f3f3f3;
    border-radius: 14px;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-container {
    width: 100px;
    height: 100px;
    border-radius: 75px;
    margin-right: 15px;

    img {
      object-fit: cover;
    }
  }

  .profile-title {
    /* The Half Moon */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    a{
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      
    }
  }
  .profile-occupation {
    /* UI/UX Designer */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #5c5c5c;
    margin-top: 4px;
    a{
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #5c5c5c;
      margin-top: 4px;
    }
  }

  .profile-mail {
    /* UI/UX Designer */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #5c5c5c;
    margin-top: 4px;
    a{
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #5c5c5c;
      margin-top: 4px;
    }
  }

  hr {
    height: 1px;
    background: #f7f7f7;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .profile-links {
    /* Edit Profile */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #707581;
  }
}


.nav-hover{

  &:hover{
    .profile-links{
      color:$base-yellow;
    }
    path{
      fill:$base-yellow;
    }
  }
}

.logout-hover{
  &:hover{
    .profile-links{
      color:red;
      cursor: pointer;
    }
  }
}