.footer-bg {
  background: $footer-yellow;
  padding-top: 24px;

  .footer-title {
    /* Health & Beauty */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: $black;
  }

  .title-type {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-top: 16px;
    cursor: pointer;
    max-width: 150px;

    a {
      text-decoration: none;
      color: $black;
    }
  }

  .download-app-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #020203;
  }

  .download-app-btn {
    width: 178px;
    max-height: 50px;
    background: #000000;
    border-radius: 10px;
    margin-top: 9px;
    padding: 6px 16px;
    cursor: pointer;

    p {
      font-family: $primary-fonts;
      font-size: 12px;
      color: $white-color;
      margin-bottom: 0;
    }
    h2 {
      font-size: 20px;
      color: $white-color;
      font-family: $primary-fonts;
      margin-bottom: 0;
      margin-top: -5px;
    }

    &:hover,
    &:focus {
      path {
        fill: $base-yellow;
        transition: 0.3s;
      }
      p {
        color: $base-yellow;
        transition: 0.3s;
      }
      h2 {
        color: $base-yellow;
        transition: 0.3s;
      }
    }
  }

  .connect-with-us {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #020203;
    margin-bottom: 12px;
    margin-right: 60px;
  }

  .playstore-padding {
    margin-top: 44px;
  }

  .connect-logo {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: $accent-black;
    display: flex;
    align-items: center;
    justify-content: center;
    // display: inline-block;
    cursor: pointer;
    path {
      fill: $white-color;
    }
    &:hover,
    &:focus {
      path {
        fill: $base-yellow;
        transition: 0.3s;
      }
    }
  }
}

.footer-bottom {
  background: $footer-gray;
  padding-top: 50px;
  padding-bottom: 50px;

  ul {
    display: flex;
    li {
      text-decoration: none;
      list-style: none;
      margin-left: 24px;

      a {
        text-decoration: none;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        /* Text/Black-100 */
        color: #020203;
      }
    }
  }

  .copyright-text {
    /* noon. All Rights Reserved */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #77778d;
  }

  .help-btn {
    background: #77778d;
    width: 90px;
    height: 40px;
    border-radius: 26px;
    font-family: "Poppins", $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: $white-color;
    border: none;

    &:focus,&:hover{
      background: $accent-black;
      color: $base-yellow;
      transition: 0.3s;

      path{
        fill: $base-yellow;
        transition: 0.3s;
      }
    }
  }
}
