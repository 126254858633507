.edit-product-modal {
  .modal-content {
    max-width: 835px;
    min-height: 772px;
    background: white;
    border-radius: 14px;
    padding: 40px 30px;
  }

  .main-heading {
    /* Add Product */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
  }
  .cross-bg {
    background: #f3f3f3;
    border-radius: 14px;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .modal-form {
    height: 567px;
    overflow-y: scroll;
    margin-top: 20px;
    padding-top: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    select.form-select {
      height: 43px;
      border-radius: 30px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #707581;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #707581;

      &:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid $base-yellow;
      }

      .dropdown-content input[type="checkbox"] {
        background: $base-yellow !important;
      }
    }

    textarea {
      border: 1px solid #ceced4;
      border-radius: 14px;
      padding: 12px 16px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #707581;
      margin-bottom: 20px;
      &:focus {
        box-shadow: none;
        border: 1px solid $base-yellow;
      }
    }

    .rmsc .dropdown-container {
      --rmsc-main: $base-yellow;
      --rmsc-hover: $base-yellow;
      --rmsc-selected: $base-yellow;

      border-radius: 20px;
      height: 43px;
      border: 1px solid #ceced4;
      margin-left: 2px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #707581;

      &.active,
      &:focus {
        box-shadow: none;
        border: 1px solid $base-yellow !important;
      }
    }
  }
}
