.dashboard-layout{
    padding-top: 15px;
    background: #F5F5F5;
    min-height: 100%;
    padding-bottom: 30px;
    &::before{
            position: absolute;
            content: '';
            background: #F5F5F5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: -1;
        
    }
    .container{
        max-width: 1400px;
    }
}