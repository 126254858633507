.checkout {
  margin-bottom: 50px;

  .form-floating select:not(:placeholder-shown) ~ label{
    background-color: white;
  }
  .add-new-card {
    /* Add New */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: $colorgray;
    padding-left: 11px;
    padding-top: 5px;
    cursor: pointer;
  }
  .checkout-main-heading {
    /* Checkout */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 16px;
    color: $black;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .checkout-subheading {
    /* Shipping Details */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $black;
    margin-bottom: 20px;
  }

  .checkout-checkbox {
    .form-check-input {
      border: 1px solid #ceced4;
      border-radius: 3px;
      width: 20px;
      height: 20px;
      margin-right: 13px;

      &:checked {
        background-color: $base-yellow;
      }
    }
  }

  .payment-checkbox {
    .form-check-input {
      border: 1px solid #ceced4;
      border-radius: 3px;
      width: 24px;
      height: 24px;
      margin-right: 13px;
      margin-top: 10px;

      &:checked {
        background-color: $base-yellow;
      }
    }
  }
}

.total-items-container {
  background: #f6f6fa;
  border-radius: 24px;
  padding: 35px 50px;
  .items-heading {
    /* Items (2) Total */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: $black;
  }

  .subhr {
    hr {
      height: 1px;
      background: $gray-line;
      margin: 0;
      margin-top: 18px;
      margin-bottom: 36px;
    }
  }

  .total-item-checkout-section {
    h4 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      color: $black;
      margin: 0;
      margin-bottom: 18px;
    }

    .subhr {
      hr {
        background: $gray-line;
        height: 1px;
        margin: 0;
        margin-bottom: 30px;
      }
    }

    .items {
      /* Sub Total: */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.0038em;
      color: $black;
    }
    .total {
      /* $140.40 */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: right;
      letter-spacing: 0.0038em;
      color: $black;
    }
    .mt_15 {
      margin-top: 15px;
    }

    .est-time {
      /* (Est time. 14-16 Nov) */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      letter-spacing: 0.0038em;
      color: #707581;
    }

    .totalhr {
      hr {
        background: $black;
        height: 1px;
        margin: 0;
        margin-top: 18px;
        margin-bottom: 15px;
      }
    }

    .proceed-btn {
      background: $black;
      color: $base-yellow;
      border: 1px solid $black;
      height: 48px;
      margin-top: 40px;
      width: 100%;
      border-radius: 100px;
      font-weight: 700;
      &:focus {
        box-shadow: none;
        outline: none;
        background: $black !important;
        color: $base-yellow !important;
        border: 1px solid $black;
      }
    }

    .continue-btn {
      background: transparent;
      color: $black;
      font-weight: 700;
      border: 1px solid $black;
      height: 48px;
      margin-top: 40px;
      margin-top: 15px;
      width: 100%;
      border-radius: 100px;
      &:focus,
      &:hover {
        box-shadow: none;
        outline: none;
        background: $black !important;
        color: $base-yellow !important;
        border: 1px solid $black;
      }
    }
  }
}

// Checkout Product Component Css Starts Here
.checkout-component {
  .img-container {
    width: 75px;
    height: 75px;
    object-fit: contain;
    border-radius: 16px;
    margin-right: 15px;
  }
  .product-checkout-title {
    /* DiW_ Shop 24/7 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $accent-black;
  }
  .product-checkout-price {
    /* USD $48 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: $black;
  }

  .checkout-price-and-quantity {
    /* $150.45 QTY: 1 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $colorgray;
  }
}
.mainhr {
  hr {
    height: 1px;
    background: $gray-line;
    margin: 0;
    margin: 25px 0;
  }
}
// Checkout Product Component Css Ends Here

// Payment Card Component Css Starts Here
.payment-card {
  margin-bottom: 25px;
  .payment-card-img {
    background: white;
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eae9e9;
    margin-right: 10px;
  }
  .payment-card-title {
    /* Stripe (connected) */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.24px;
    color: $black;
    span {
      color: $colorgray;
    }
  }
  .payment-code {
    /* •••• 1234 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: #7c7c7e;
  }
}
// Payment Card Component Css Ends Here

// Review Css Starts Here
.review {
  .shipping-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $black;
  }
  .data-ques {
    /* PN0001265 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $colorgray;
    width: 60px;
  }
  .data-ans {
    /* PN0001265 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    padding-left: 70px;
  }

  .mt_15 {
    margin-top: 15px;
  }

  .payment-heading {
    /* Payment Method */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: $black;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
// Review Css Ends Here

// Order Summary Css Starts Here
.order-summary-page {
  background: #fbfbfd;
  border-radius: 10px;
  padding: 25px;
  height: 693px;

  .order-no {
    /* Dashboard */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $black;
      span{
        color: $base-yellow;
      }
  }
}
// Order Summary Css Ends Here


.checkout-signup {
  .form-check-input {
    border: 1px solid #ceced4;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    margin-right: 13px;



    &:checked {
      background-color: $base-yellow;
    }
  }
  label.form-check-label{
    /* I would like to receive additional news about offers. */
font-family: 'Ageo';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #3A3B3B;


  }

}