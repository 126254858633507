.report-chat {
  .modal-content {
    width: 431px;
    height: 342px;
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
    border-radius: 14px;
    margin: 0 auto;
    padding: 30px;
  }

  .modal-title {
    /* Report Chat */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
  }

  .cross-bg {
    background: #f3f3f3;
    border-radius: 14px;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .report-conditions {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #707581;
    padding-left: 10px;
  }

  input[type="radio"]:checked + label {
    background-color: white !important;
  }

  input[type="radio"]:checked {
    background-color: red !important;
  }
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #707581;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $base-yellow;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

    .main-btn {
    width: 160px;
    height: 40px;
    background: $black;
    color: $base-yellow;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    border-radius: 20px;
    border: 1px solid $black;
    cursor: pointer;
    &:focus,
    &:hover {
      background: $black !important;
      box-shadow: none;
      border: 1px solid $black;
      color: $base-yellow !important;
    }
  }

  .sub-btn {
    width: 100px;
    height: 40px;
    border: none;
    box-shadow: none;
    background: transparent;
    /* Button */

    font-family: "Ageo";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;

    /* Black */
    color: #000000;
    &:focus,
    &:hover {
      background: transparent !important;
      /* Black */
      color: #000000 !important;
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
