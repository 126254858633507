// Auth Responsiveness Starts Here

// Get Started Css Starts Here

// Get Started Css Ends Here

// Home Responsive Css Starts Here
@media (max-width: 1400px){
    .nav-multidropdown .test .new-test .new-test-result {
        display: none;
        position: absolute;
        background: #fdfdff;
        width: 450px;
        height: 100%;
        left: 228px;
        top: 0;
        border-left: 1px solid #e3e3f1;
        padding-top: 15px;
        padding-left: 30px;
        padding-right: 50px;
    }
    .nav-multidropdown .test {
        top: 20px;
        display: none;
        position: absolute;
        background: #fdfdff;
        height: 628px;
        width: 230px;
        z-index: 999;
        padding-top: 15px;
    }
        /* QA fixes height */
        .auth-container {
          height: 600px;
      }
}
@media (max-width: 1200px) {
    .navbar-bg .header-bottombar .menu-options ul li {
        margin-right: 15px;
        font-family: "Ageo", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $black;
    }

    .product-details-accordion{
      margin-top: 40px;
    }

    .product-card-bg {
    min-width: 100%;
    min-height: 350px;
    background: #f6f6fa;
    border-radius: 16px;
}


  }
@media (max-width: 1050px){
  .cart-screen-section{
   .form-check-input {
    border: 1px solid #ceced4;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    margin-top: 20px;
    margin-right: 20px;
}
}
.auth-container{
  background: unset;
}
.auth-main-section-margin{
  margin: 0 auto;
}
}
@media (max-width: 768px) {
  .banner-bg {
    height: 100%;
    padding: 30px 0;
  }

  .add-to-cart-modal{
    .modal-dialog{
      max-width: 100%!important;

      .modal-content{
        min-width: 100%!important;
        width: 100% !important;
        max-width: 100%!important;
      }
    }
  }


  .add-to-cart-modal .modal-dialog .modal-content {
    background: #ffffff;
    border-radius: 14px;
    height: 100%!important;
    min-width: 100%;
}


  .tab-buttons-section{
    .nav-pills {
    
      display: block;
  }
  }

  .main-order-view{
    .table{
      width: 500px;
      overflow: scroll;
    }
  }

  .main-my-orders .tab-buttons-section li:not(:first-child) {
    margin-left: 0;
    margin-top: 10px;
}

  .data-section{
    margin-left: 0!important;
    margin-top: 20px;
  }

  .footer-title{
    margin-top: 20px;
    font-weight: 700!important;
    font-size: 20px!important;
  }

    .buyer-product-details{
      .tab-btn-container{
        margin-bottom: 20px;
      }
    .side-border {
      border-left: none;
      padding-left: 0;
      height: 100%;

      
  .buyer-product-title{
    font-size: 30px;
    font-weight: 600;
    margin-top: 30px;
  }

  .made-in-section{
    width: 100%;
  }
  }

  .add-to-cart-btn{
    margin-top: 30px;
  }

}

.product-listing-banner {
  .banner-container{
  .banner-title {
    width: 100%;
}
  }
}

.order-summary-page {
  background: #fbfbfd;
  border-radius: 10px;
  padding: 25px;
  height: 100%;
}
.total-items-container {
  margin-top: 20px;
}

.heart-container{
  margin-top: 20px;
}
.buyer-navbar-profile{
  margin-top: 20px;
}

.auth-container{
  margin-top: 50px!important;

}

}

@media screen and (max-width: 576px) {
  .profile-modal .modal-dialog {
    margin-right: 0; 
}
.modal-content{
  width: 100% !important;
  margin: 10px;

}
}
@media (max-width: 450px){

  .main-buyer-account-details .buyer-account-content .buyer-info ul li span {
    font-family: "Ageo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3a3b3b;
    display: block!important;
}
.main-buyer-account-details{
  padding-bottom: 20px;
}
  .buyer-product-details{
    .tab-btn-container{
      margin-bottom: 20px;
    }
  .side-border {
    border-left: none;
    padding-left: 0;
    height: 100%;

    .size{
      margin-top: 10px;
    }

    
.buyer-product-title{
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}

.made-in-section{
  width: 100%;
}
}

.add-to-cart-btn{
  margin-top: 30px;
}

}

.product-listing-banner .banner-container {
    padding-left: 15px;
}

.category-btn-offcanvas .boxes-section .category-boxes {
width:100%;
}

.category-btn-offcanvas{
  padding: 10px
}

.filter-btn-offcanvas {
  .filter-conditions-section {
  padding-left: 0;
}
.filter-heading{
  margin-left: 0;
}
}
}

@media (max-width: 420px){
  .wishlist{
    .wishlist-grid{ 
      overflow-x: scroll;

    
    .form-check-input {
    border: 1px solid #ceced4;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    margin-top: 20px;
    margin-right: 20px;
}
}
.product-name {
  font-family: "Ageo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-left: 15px;
}
}

.total-item-section {
  background: #f6f6fa;
  padding: 15px 20px;
  border-radius: 24px;
  margin-bottom: 60px;
}

.total-items-container {
  background: #f6f6fa;
  border-radius: 24px;
  padding: 15px 20px;
}
}
// Home Responsive Css Ends Here


// Auth Responsiveness Ends Here


div#offcanvasNavbar-expand-md{
  background: $navbar-bg;

  

  .divider {
    hr {
      /* Separator */
      height: 1px;
      background: #e3e3f1;
      border-radius: 8px;

      margin-top: 0;
    }
  }

  .search-bar {
    input.form-control {
      border-radius: 14px;
      border: none;
      border: 1px solid #e3e3f1;
      border-right: none;
      font-family: "Ageo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #8888a2;
      padding: 14px;
      max-width: 100%;
      height: 48px;
      background: white;
      border-radius: 14px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:focus{
        box-shadow: none;
        outline: none;
      }
    }

    span#basic-addon2 {
      background: wheat;
      background: white;
      border: 1px solid #e3e3f1;
      border-left: none;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }

  .signin-btn {
    background: $primary-btn-bg;
    width: 79px;
    height: 48px;
    font-family: "Ageo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $btn-text;
    border: none;
    border-radius: 20px;
    &:focus,
    &:hover {
      background: $accent-black;
      color: $base-yellow;
      transition: 0.3s;
    }
  }

  .heart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 48px;
    height: 48px;
    background: $white-color;
    box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
    border-radius: 24px;
  }

}


.iconfeildauth {
  position: absolute;
  bottom: 10px;
  right: 13px;
  background-color: $white-color;
  width: 30px;
  text-align: end;
}



















// Media Querries to set popup of my profile modal


@media (max-width:1920px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:270px;
      top:40px;
    }
  }
}

@media (max-width:1820px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:220px;
      top:40px;
    }
  }
}

@media (max-width:1720px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:160px;
      top:40px;
    }
  }
}

@media (max-width:1620px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:100px;
      top:40px;
    }
  }
}

@media (max-width:1520px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:80px;
      top:40px;
    }
  }
}

@media (max-width:1420px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:20px;
      top:40px;
    }
  }
}

@media (max-width:325px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:18px;
      top:40px;
    }
  }
}

@media (max-width:325px){
  .profile-modal {
    .modal-dialog{
      position: fixed;
      right:16px;
      top:40px;
    }
  }
}
// Media Querries to set popup of my profile modal ends here


