.inbox-section {
  background: #ffffff;
  border-radius: 14px;
  height: 647px;
  padding: 20px;

  .search-box {
    input {
      background: #fbfbfd;
      border: none;
      max-width: 354px;
      height: 40px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border: 1px solid white !important;
      border-left: none;

      /* Search */

      font-family: "Ageo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #7d8592;

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }

    span#basic-addon1 {
      background: #fbfbfd;
      border: 1px solid white;
      border-right: none;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding-left: 15px;
      cursor: pointer;
      :hover {
        path {
          fill: $base-yellow;
        }
      }
    }
  }

  .no-msg {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */
    text-align: center;
    letter-spacing: 0.15px;
    /* Grey */
    color: $colorgray;
    display: block;
  }
}

.messages-section {
  background: #ffffff;
  border-radius: 14px;
  height: 647px;
  padding: 30px 20px;
  position: relative;

  .author-name {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  hr {
    height: 1px;
    border: 1px solid #c9c9c9a4;
    margin: 0;
    margin-top: 30px;
  }

  // .custom-textarea {
  //   ::placeholder {
  //     padding-top: 18px;
  //     padding-left: 10px;
  //   }
  // }

  textarea {
    border: 1px solid #ceced4;
    border-radius: 20px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ceced4;
    width: 581px;
    min-height: 43px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: none;
    border: 1px solid $colorgray;
    margin: 0 10px;

    resize: none;
    padding-top: 18px !important;
    padding-left: 10px !important;
    &:focus {
      box-shadow: none;
      border: 1px solid $colorgray;
      padding-top: 18px !important;
      padding-left: 10px !important;
      ::placeholder {
        display: none;
      }
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }
  textarea::placeholder {
    // padding-top: 18px !important;
    // padding-left: 10px !important;
    // position: absolute;
  }
  textarea:focus::placeholder {
    color: transparent;
  }

  .messages-area {
    height: 470px;
  }

  .attachment-bg {
    background: #fbfbfd;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    cursor: pointer;
    border-radius: 10px;
  }

  .send-bg {
    height: 43px;
    width: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 10px;
    cursor: pointer;
  }

  .message-position {
    position: absolute;
    bottom: 30px;
  }
}
