.product-card-bg {
  min-width: 270px;
  min-height: 350px;
  background: #f6f6fa;
  border-radius: 16px;

  .img-container {
    position: relative;
  }

  .top-right {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 8px;
  }

  .price-tag {
    width: 105px;
    height: 40px;
    background: $white-color;
    border: 1px solid $base-yellow;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .price {
      /* $122 */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black;
    }

    strike {
      color: red;
    }

    .original-price {
      /* $154 */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #8888a2 !important;
      margin-left: 8px;
    }
  }

  .icon-container {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white-color;
    border-radius: 24px;
    cursor: pointer;
  }

  .product-desc {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $black;
    margin-top: 12px;
    padding: 0 12px;
    padding-bottom: 10px;
  }
}

.product-category-heading {
  width: 270px;
  height: 29px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.product-category-desc {
  /* All Categories */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #77778d;
}

.mb_8 {
  margin-bottom: 8px;
}

.mb_16{
  margin-bottom: 16px;
}
