.cart-empty-section {
  .empty-msg {
    background: $footer-yellow;
    margin-top: 40px;
    border-radius: 32px;
    padding: 22px 30px;
    margin-bottom: 30px;

    .msg-text {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: $black;
      padding-left: 24px;
    }
  }

  .empty-bag-text {
    /* Your bag is empty */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #77778d;
    text-align: center;
    margin-top: 26px;
  }

  .cart-bag {
    margin-top: 10px;
    margin-bottom: 140px;
  }
}

// Cart Screen Css Starts Here
.cart-screen-section {
  .no-of-items {
    /* 2 Items in your cart */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: $black;
    user-select: none!important;
  }

  label{width: 90%;}


  .delete-txt {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #f76356;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 8px;
    }
  }

  .form-check-input {
    border: 1px solid #ceced4;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    margin-top: 20px;
    margin-right: 47px;

    &:checked {
      background-color: $base-yellow;
    }
  }
}

.total-item-section {
  background: $navbar-bg;
  padding: 35px 50px;
  border-radius: 24px;
  margin-bottom: 60px;

  h4 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: $black;
    margin: 0;
    margin-bottom: 18px;
  }

  .subhr {
    hr {
      background: $gray-line;
      height: 1px;
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .items {
    /* Sub Total: */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.0038em;
    color: $black;
  }
  .total {
    /* $140.40 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    letter-spacing: 0.0038em;
    color: $black;
  }
  .mt_15 {
    margin-top: 15px;
  }

  .est-time {
    /* (Est time. 14-16 Nov) */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.0038em;
    color: #707581;
  }

  .totalhr {
    hr {
      background: $black;
      height: 1px;
      margin: 0;
      margin-top: 18px;
      margin-bottom: 15px;
    }
  }

  .proceed-btn {
    background: $black;
    color: $base-yellow;
    border: 1px solid $black;
    height: 48px;
    margin-top: 40px;
    width: 100%;
    border-radius: 100px;
    &:focus {
      box-shadow: none;
      outline: none;
      background: $black !important;
      color: $base-yellow !important;
      border: 1px solid $black;
    }
  }

  .continue-btn {
    background: transparent;
    color: $black;
    border: 1px solid $black;
    height: 48px;
    margin-top: 40px;
    margin-top: 15px;
    width: 100%;
    border-radius: 100px;
    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
      background: $black !important;
      color: $base-yellow !important;
      border: 1px solid $black;
    }
  }
}

.cartitemscard {
  margin-bottom: 20px;
  user-select: none!important;




  .cartimg-box {
    width: 75px;
    height: 75px;
    border-radius: 16px;
    object-fit: contain;
  }
  .cart-item-title {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    padding-bottom: 5px;
  }
  
  .ps_20{
    padding-left: 20px;
  }
  .main-price {
    /* $120.20 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black;
  }
  .sub-price {
    /* $150.45 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $cart-sub-price;
    padding-left: 10px;
    strike{
      color: $cart-sub-price;
    }
  }
}

.quantity-btn {
  width: 103px;
  height: 48px;
  border: 1px solid $black;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 10px;
}



.count-text{
font-family: $primary-fonts;
font-style: normal;
font-weight: 400;
font-size: 16px;
color: black;
}

// Cart Screen Css Ends Here


