.auth-container {
  height: 730px;
  background: url("/src/assets/images/Auth/authbg.png");
  background-repeat: no-repeat;
  background-position: right;
  margin: auto;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1110px;

  hr {
    opacity: 0.2;
    /* Logo Yellow */
    border: 1px solid #dbbb51;
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  h2 {
    /* Welcome to Afrotierre */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
    margin: 0;
  }

  h4 {
    /* Select Account Type */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin: 0;
    margin-top: 30px;
  }

  h6 {
    /* Is your Afrotierre account Buyer or Seller? */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    margin-top: 30px;

    span {
      font-weight: 700;
    }
  }

  .new-hr {
    width: 120px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .auth-btn {
    /* Button */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    display: inline-block;

    /* Black */
    background: #000000;
    border-radius: 20px;
    border: none;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #dbbb51;
    margin-top: 40px;

    &:focus {
      background: #000000;
    }

    &:hover {
      background: $base-yellow;
      color: black;
      transition: 0.2s ease-in;
    }
  }

  .already-account {
    /* Already have an account? */
    font-family: "Ageo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 8px;
    color: #5c5c5c;
    margin-top: 4px;
  }

  .already-acc-link {
    /* Sign In */
    a, .resend {
      text-decoration: none;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 27px;
      text-transform: uppercase;
      color: #000000;
      cursor: pointer;

      &:hover {
        color: $base-yellow;
      }
    }
  }

  .as-guest {
    margin-top: 20px;
    margin-bottom: 20px;

    a {
      text-decoration: none;
      /* Continue as guest */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* Grey_dark */
      color: #979797;

      &:hover {
        color: $base-yellow;
      }
    }
  }
}

// Select Role Css

.select-role {
  input {
    display: none;
  }

  label:checked {
    .icon-container {
      background: black;
    }
  }

  label {
    width: 350px;
    height: 90px;
    border-radius: 20px;
    padding: 15px 0 15px 20px;
    background: #f6f6fa;
    border: 1px solid #ceced4;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-container {
      background: white;
      width: 60px;
      height: 60px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }

    .register-as {
      /* Register as a Buyer */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }
  }
}

input[type="radio"]:checked + label {
  background-color: $base-yellow;
}

input[type="radio"]:checked {
  .icon-container {
    background: black;
  }
}

.signup-form {

  .form-floating {
    label {
      font-family: "Ageo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #707581;
      padding: 13px 16px;

      &:focus {
        position: absolute;
        top: -10px !important;
      }
    }

    textarea {
      width: 100%;
      height: 43px;
      border: 1px solid #ceced4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      font-family: "Ageo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-bottom: 20px;
      padding: 13px 16px !important;
      height: 100px;
      resize: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid $base-yellow;

        label {
          color: blue !important;
        }
      }

      &:not(:placeholder-shown) ~ label {
        background: white;
        padding: 0;
        margin: 0;
        top: 0;
        opacity: 1;
        display: inline;
        width: auto;
        height: auto;
        left: 12px;
        padding: 0 8px;
      }

      &:focus ~ label {
        background: white;
        padding: 0;
        margin: 0;
        top: 0;
        opacity: 1;
        display: inline;
        width: auto;
        height: auto;
        left: 12px;
        padding: 0 8px;
      }
    }

    input {
      width: 100%;
      height: 43px;
      border: 1px solid #ceced4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      font-family: "Ageo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-bottom: 35px;
      padding: 13px 16px !important;

      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid $base-yellow;

        label {
          color: blue !important;
        }
      }

      &:not(:placeholder-shown) ~ label {
        background: white;
        padding: 0;
        margin: 0;
        top: 0;
        opacity: 1;
        display: inline;
        width: auto;
        height: auto;
        left: 12px;
        padding: 0 8px;
      }

      &:focus ~ label {
        background: white;
        padding: 0;
        margin: 0;
        top: 0;
        opacity: 1;
        display: inline;
        width: auto;
        height: auto;
        left: 12px;
        padding: 0 5px;
      }
    }

    // &:focus {
    //   label {
    //     position: absolute;
    //     top: -10px!important;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     padding: 1rem 0.75rem;
    //     overflow: hidden;
    //     text-align: start;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     pointer-events: none;
    //     border: 1px solid transparent;
    //     transform-origin: 0 0;
    //     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    //     color: blue !important;
    //   }
    // }
    select {
      width: 100%;
      height: 43px;
      border: 1px solid #ceced4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      font-family: "Ageo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-bottom: 30px;
      padding: 13px 16px !important;
      resize: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid $base-yellow;

        label {
          color: blue !important;
        }
      }

      &:not(:placeholder-shown) ~ label {
        background: $white-color;
        padding: 0;
        margin: 0;
        top: 0;
        opacity: 1;
        display: inline;
        width: auto;
        height: auto;
        left: 12px;
        padding: 0 8px;
      }

      &:focus ~ label {
        background: #f6f6fa;
        padding: 0;
        margin: 0;
        top: 0;
        opacity: 1;
        display: inline;
        width: auto;
        height: auto;
        left: 12px;
        padding: 0 8px;
      }
    }
  }
}

// OTP

.otpinputfeild input {
  //   border-bottom: 1px solid #ccc;
  //   border-radius: 0;
  //   color: #4a4a4a;
  //   font-size: 14px;
  //   font-weight: 400;
  //   margin-bottom: 10px;
  //   padding: 10px 15px 0;
}

.otp-div {
  position: relative;

  .otp {
    overflow: hidden !important;

    input {
      width: 48px !important;
      height: 48px;
      background: #ffffff;
      border: 1px solid #cfcdd6;
      border-radius: 15px;
      margin: 0 7px !important;
    }

    input {
      &:focus {
        outline: none;
        border: 2px solid $base-yellow !important;
        // background-color: red;
      }
    }
  }
}

.timer {
  /* 0:45 */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #7c7c7e;
  margin-top: 20px;
  margin-left: 10px;

  p {
    margin: 0
  }
}

// .otp-div {
//   .otpinputfeild {
//     width: 100%;
//     input {
//       border: 1.28827px solid #af2a2a !important;
//       border-radius: 10.3061px;
//       height: 60px !important;
//       width: 60px !important;
//       margin: 0 35px !important;
//       font-family: $primary-fonts;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 18px;
//       text-align: center;
//       color: #23143e!important;

//       &:focus {
//         border: 1px solid #00bcc1 !important;
//         outline: #00bcc1 !important;
//         color: #23143e!important;

//       }
//       &:first-child{
//         margin-left: 0 !important;
//       }
//       // &:last-child{
//       //   margin: 0 0;
//       // }
//     }
//   }

//   .timer {
//     margin-top: 21px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     .text-red {
//       color: red;
//     }
//     p {
//       margin: 0;
//       font-family: $primary-fonts;
//       font-style: normal;
//       font-weight: 300;
//       font-size: 16px;
//       color: #242424;
//       span{
//         color: #00BCC1;
//         font-weight: 500;
//       }
//     }
//     .aa{

//         margin-left: auto;
//     }
//   }
// }

.forgot-password {
  a {
    text-decoration: none;
    font-family: "Ageo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #979797;
  }
}

a {
  text-decoration: none;
}

.camera-div {
  position: absolute;
  top: 250px;
  /* bottom: 0; */
  left: 70px;
  /* bottom: 55px; */
  width: 32px;
  background-color: #fdfdff;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #f6f6fa;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.footer-bottom-hr {
  height: 1px;
  margin: 0;
  margin: 0;
  margin-top: 50px;
  background: #dcdcdc;
  margin-bottom: 34px;
}

.footer-copyrights {
  /* © 2022 Afrotierre. All Rights Reserved */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #555555;

  b {
    font-weight: 700;
    color: black;
  }
}

.social-links-container {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: #dbbb51;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $black;
    transition: 0.3s;

    path {
      fill: $base-yellow;
      transition: 0.3s;
    }
  }
}

.social-login {
  margin-top: 25px;

  .social-login-text {
    /* or sign in with social accounts */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0038em;
    color: #707581;
  }

  .apple-btn {
    height: 42px;
    border: 1px solid $black;
    /* Continue with Apple */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    background: transparent;
    width: 100%;
    border-radius: 30px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $black;

    span {
      padding-left: 10px;
    }

    &:focus {
      background: none !important;
      color: $black;
    }
  }

  .google-btn {
    height: 42px;
    border: 1px solid #d9d9d9;
    font-family: $primary-fonts;
    /* Continue with Google */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: transparent;
    width: 100%;
    border-radius: 30px;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-left: 10px;
    }

    &:focus {
      background: none !important;
      color: rgba(0, 0, 0, 0.54);
      border: 1px solid #d9d9d9;
    }
  }

  .fb-btn {
    height: 42px;
    border: 1px solid #1877f2;
    background: transparent;
    border-radius: 30px;
    width: 100%;
    /* Continue with Facebook */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1877f2;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-left: 10px;
    }

    &:focus {
      background: none !important;
      color: #1877f2;
      border: 1px solid #1877f2;
    }
  }
}

button {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.privacy {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707581;

  span {
    font-weight: 700;
    color: black;
    text-transform: uppercase;
  }
}

.pagination {
  margin-bottom: 50px;

  .page-item:last-child .page-link {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .page-item:first-child .page-link {
    border-radius: 24px;
    width: 48px !important;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a.page-link {
    margin-left: 10px !important;
    border-radius: 24px;
    background: #f0f0f6;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #8888a2;
    width: 48px !important;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
  }

  .active > .page-link, .page-link.active {
    background: $black;
    color: $base-yellow;
    border: 1px solid $black;
  }
}


.home-responsive-swiper {
  .swiper {
    background: #FDFDFF !important;
    box-shadow: none !important;
  }

  .swiper-slide {
    img {
      height: 280px;
      border-radius: 16px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #ceced4;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}


.multi-image-uploader {

  input {
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }

  .form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer;
    background: black;
    width: 130px !important;
    height: 110px;
    background: #DBBB51;
    border-radius: 20px;
  }

  .swiper-slide.swiper-slide-active {
    // margin-right: 60px!important;
    width: 110px !important;

  }

  .swiper-slide.swiper-slide-next {
    // margin-right: 60px!important;
    width: 110px !important;

  }

  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    box-shadow: none;
  }

  .box {
    width: 110px;
    height: 110px;
    margin-right: 20px;

    img {
      border-radius: 20px;
    }
  }
}

.PhoneInputCountry .PhoneInputCountryIcon g {
  display: none;
}

.PhoneInputCountry .PhoneInputCountrySelectArrow {
  position: relative;
  left: 0px;
}