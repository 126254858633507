.dashboard-sidebar {
  /* elm/card/main */
  background: #ffffff;
  border-radius: 14px;
  width: 100%;
  height: 774px;
  padding-left: 20px;

  .logo-container {
    padding-top: 45px;
    padding-bottom: 30px;
  }

  ul {
    text-decoration: none;
    list-style: none;
    // padding-left: 8px;
    padding-left: 0;

    li {
      text-decoration: none;
      color: #899ca8;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      position: relative;

      a {
        text-decoration: none;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #707581;
        line-height: 17px;
        cursor: pointer;
        min-height: 44px;
        // padding: 12px 28px 12px 10px;

        .nav-bg {
          padding-left: 12px;
          height: 44px;
          display: flex;
          align-items: center;
        }

        /* identical to box height */

        /* 3 */

        .settings-icon {
          padding-right: 10px;
        }

        &.active {
          // background: #3b79fe2c;
          // border-radius: 10px;
          // padding: 12px 28px 12px 10px;
          color: #dbbb51;
          font-weight: 700;
          transition: 0.2s ease-out;

          .nav-bg {
            background-color: #fbf8ee;
            width: 100%;
            margin-right: 20px;
            height: 44px;
            display: flex;
            align-items: center;
            border-radius: 20px;
            padding-left: 12px;
          }

          path {
            fill: #dbbb51;
          }
        }
      }

      .non-active {
        text-decoration: none;
        color: #899ca8 !important;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
        cursor: pointer;
        min-width: 182px;
        min-height: 44px;
        background: transparent !important;

        path {
          fill: #899ca8 !important;
        }

        ::after {
          display: none !important;
        }
      }
    }
  }
}

.projectids{
  .fashionbold {
    /* PN0001265 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
  }
  
  .subid {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
    margin-left: 20px;
  }
  
  .subsubid {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
    margin-left: 30px;
    text-align: left;
  }
}

.MuiDataGrid-row {
  background: #FCFCFC;
}
.projectids:has(.fashionbold){
  .MuiDataGrid-row {
    background: red;
  }
}


.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  overflow: hidden;
  white-space: nowrap;
  outline: none!important;
}