.main-report-center {
  margin-top: 30px;
  margin-bottom: 106px;
  h3 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
    padding-bottom: 16px;
  }
  h4 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
  h5 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
  p {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7c7c7e;
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .main-report-type-div {
    .report-type-div {
      background: #f6f6fa;
      border-radius: 8px;
      padding: 15px 20px;

      h4 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
      .main-submit-div {
        text-align: right;
        a {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          margin-left: auto;
          font-weight: 700;
          font-size: 16px;
          color: #dbbb51;
          height: 48px;
          width: 200px;
          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          border: none;
          display: block;
          line-height: 48px;

          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
      }
      &:not(:first-child) {
        margin-top: 25px;
      }
    }
  }
}

/* main report form */
.main-report-center {
  margin-top: 30px;
  margin-bottom: 106px;
  h3 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
    padding-bottom: 16px;
  }
  h4 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .main-report-type-div {
    form {
      .form-floating {
        label {
          font-family: "Ageo";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #707581;
          padding: 13px 16px;
          &:focus {
            position: absolute;
            top: -10px !important;
          }
        }

        input {
          width: 100%;
          height: 43px;
          border: 1px solid #ceced4;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 25px;

          padding: 13px 16px !important;

          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $base-yellow;

            label {
              color: blue !important;
            }
          }

          &:not(:placeholder-shown) ~ label {
            background: #ffffff;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }

          &:focus ~ label {
            background: #ffffff;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }
        }

        // &:focus {
        //   label {
        //     position: absolute;
        //     top: -10px!important;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     padding: 1rem 0.75rem;
        //     overflow: hidden;
        //     text-align: start;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     pointer-events: none;
        //     border: 1px solid transparent;
        //     transform-origin: 0 0;
        //     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
        //     color: blue !important;
        //   }
        // }
        textarea {
          width: 100%;
          height: 43px;
          border: 1px solid #ceced4;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 20px;
          padding: 13px 16px !important;
          height: 100px;
          resize: none;
          &::-webkit-scrollbar {
            display: none;
          }
          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $base-yellow;

            label {
              color: blue !important;
            }
          }

          &:not(:placeholder-shown) ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }

          &:focus ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }
        }
        select {
          width: 100%;
          height: 43px;
          border: 1px solid #ceced4;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 20px;
          padding: 13px 16px !important;
          resize: none;
          &::-webkit-scrollbar {
            display: none;
          }
          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $base-yellow;

            label {
              color: blue !important;
            }
          }

          &:not(:placeholder-shown) ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }

          &:focus ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }
        }
      }
      .form-group {
        margin-bottom: 20px;
      }
      .form-label {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black;
        margin-bottom: 8px;
        background-color: transparent;
      }
      .form-control {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #3a3b3b;
        background: transparent;
        border: 1px solid #ceced4;
        border-radius: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;
        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          display: none;
        }
      }
      .form-select {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: 1px solid #ebebf9;
        border-radius: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;
        background-image: url("../images/BuyerProfile/drop-down-arrow.png");
        background-size: 11px;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        display: block;
        width: 100%;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
        cursor: pointer;
        &:focus {
          box-shadow: none;
        }
      }
      .button-div {
        display: flex;
        justify-content: end;
        button {
          margin-top: 10px;
          margin-bottom: 40px;
          background: $black;
          border-radius: 20px;
          height: 48px;
          max-width: 200px;
          width: 100%;
          color: #dbbb51;
          text-transform: capitalize;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border: none;
          transition: 0.2s ease-in;
          &:focus {
            box-shadow: none;
          }
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
          }
        }
      }
    }
  }
}
