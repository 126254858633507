.orderno-bg {
  background: white;
  width: 100%;
  height: 356px;
  border-radius: 14px;
  padding: 20px;

  h2 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #121212;
    margin-bottom: 5px;
  }

  .order-no {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $base-yellow;
  }

  .order-data {
    margin-top: 15px;
    .order-ques {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $colorgray;
    }
    .order-ans {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $black;

      .new {
        height: 38px;
        width: 105px;
      }
      .completed {
        height: 38px;
        width: 105px;
      }

      .css-13cymwt-control,
      .css-t3ipsp-control {
        height: 26px;
        width: 105px;
        border-radius: 20px;
        padding-left: 2px;
        padding-right: 2px;
      }
      .css-1dimb5e-singleValue {
        grid-area: 1/1/2/3;
        max-width: 100%;
        overflow: visible;
        text-overflow: initial;
        white-space: nowrap;
        color: hsl(0, 0%, 20%);
        margin-left: 10px;
        box-sizing: border-box;
      }
      .css-1xc3v61-indicatorContainer,
      .css-15lsz6c-indicatorContainer {
        margin-right: 10px;
      }
      span.css-1u9des2-indicatorSeparator {
        display: none;
      }
      .css-1fdsijx-ValueContainer {
        overflow: visible;
        padding: 2px 0px;
      }

      .css-1xc3v61-indicatorContainer,
      .css-15lsz6c-indicatorContainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-transition: color 150ms;
        transition: color 150ms;
        color: hsl(0, 0%, 80%);
        padding: 0px;
        box-sizing: border-box;
      }
      .Select__control:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.order-detail-table {
  background: #ffffff;
  border-radius: 14px;
  height: 506px;
  padding: 20px;
}

.reject-btn {
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  /* Black */
  color: $black;
  background: transparent;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid $black;
  margin-top: 20px;
  &:hover,
  &:focus {
    background: $base-yellow !important;
    color: $black !important;
    border: 1px solid $base-yellow !important;
  }
}

.accept-btn {
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  /* Black */
  color: $base-yellow;
  background: $black;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid $black;
  margin-left: 20px;
  margin-top: 20px;

  &:hover,
  &:focus {
    background: $base-yellow !important;
    color: $black !important;
    border: 1px solid $base-yellow !important;
  }
}

.table-search-box {
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  input {
    background: #fbfbfd;
    border: none;
    width: 334px !important;
    height: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid white !important;
    border-left: none;

    /* Search */

    font-family: "Ageo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #7d8592;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      background: #fbfbfd;
    }
  }

  span#basic-addon1 {
    background: #fbfbfd;
    border: 1px solid white;
    border-right: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 15px;
    cursor: pointer;
    :hover {
      path {
        fill: $base-yellow;
      }
    }
  }
}

.table-right-btn {
  width: 160px;
  height: 40px;
  background: $black;
  color: $base-yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Button */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-radius: 20px;
  border: 1px solid $black;
  margin-left: 10px;

  &:focus,
  &:hover {
    box-shadow: none;
    border: 1px solid $black;
    background: $black !important;
    color: $base-yellow;
  }
}

.order-dropdown {
  .css-qbdosj-Input {
    width: 100px;
  }
  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 160px !important;
    height: 40px !important;
  }
}

.category-parent-dropdown {
  margin: 20px 0;
  .css-qbdosj-Input {
    height: 33px;
    font-family: $primary-fonts !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #707581 !important;
  }

  .parent-opt {
    /* Parent */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #707581;
  }

  .sub-parent {
    // padding-left: 20px;
  }
}

.category-img-div {
  position: relative;
  width: 89px;
  height: 89px;
  border-radius: 14px;
  margin-bottom: 24px;

  img {
    border-radius: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
  }

  .file-upload {
    width: 90px;
    height: 90px;
    border-radius: 14px;
    overflow: hidden;
  }
}

.table-content-center {
  display: flex;
  flex-direction: column;
  .css-17jjc08-MuiDataGrid-footerContainer {
    display: none !important;
  }
  .MuiDataGrid-footerContainer.css-1yy0dv2 {
    display: none !important;
  }
  .MuiDataGrid-footerContainer {
    display: none !important;
  }
}

/* dispatched tracking details */
.main-dispatched-td {
  .form-div {
    .img-upload-div {
      width: 89px;
      height: 89px;
      overflow: hidden;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  form {
    h3 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #707581;
      padding-bottom: 11px;
      margin-bottom: 0;
    }
    .form-floating {
      label {
        font-family: "Ageo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
        padding: 13px 16px;
        &:focus {
          position: absolute;
          top: -10px !important;
        }
      }

      input {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 25px;

        padding: 13px 16px !important;

        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #ffffff;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
    }
    .form-group {
      margin-bottom: 20px;
    }
    .form-label {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      margin-bottom: 8px;
      background-color: transparent;
    }
    .form-control {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #3a3b3b;
      background: transparent;
      border: 1px solid #ceced4;
      border-radius: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        display: none;
      }
    }

    .button-div {
      display: flex;
      justify-content: end;
      button {
        margin-top: 10px;
        margin-bottom: 40px;
        background: #000000;
        border-radius: 20px;
        height: 40px;
        max-width: 160px;
        width: 100%;
        color: #dbbb51;
        text-transform: capitalize;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        border: none;
        transition: 0.2s ease-in;
        padding: 0;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: #dbbb51;
          color: #ffffff;
        }
      }
    }
  }
  .orderno-bg {
    background: white;
    width: 100%;
    height: 356px;
    border-radius: 14px;
    padding: 20px;
    margin-top: 30px;
    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #121212;
      margin-bottom: 16px;
    }

    .order-no {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $base-yellow;
    }

    .order-data {
      margin-top: 15px;
      .order-ques {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $colorgray;
      }
      .order-ans {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $black;

        .new {
          height: 38px;
          width: 105px;
        }
        .completed {
          height: 38px;
          width: 105px;
        }
      }
    }
  }
}

/* completed tracking details */
/* dispatched tracking details */
.main-completed-td {

  .orderno-bg {
    background: white;
    width: 100%;
    height: 244px;
    border-radius: 14px;
    padding: 20px;
    margin-top: 30px;
    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #121212;
      margin-bottom: 16px;
    }

    .order-no {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $base-yellow;
    }

    .order-data {
      margin-top: 15px;
      .order-ques {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $colorgray;
      }
      .order-ans {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $black;

        .new {
          height: 38px;
          width: 105px;
        }
        .completed {
          height: 38px;
          width: 105px;
        }
      }
    }
  }
}
