.delete-modal {
  .modal-content {
    max-width: 431px;
    height: 225px;
    border-radius: 10px;
    background: white;
    padding: 32px;
    margin: 0 auto;
  }

  .main-heading {
    /* Info */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    color: #000000;
  }

  p {
    margin-top: 32px;
    margin-bottom: 0 !important;
    /* Content */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    /* Grey */
    color: #707581;
  }

  .main-btn {
    width: 160px;
    height: 40px;
    background: $black;
    color: $base-yellow;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    border-radius: 20px;
    border: 1px solid $black;
    cursor: pointer;
    &:focus,
    &:hover {
      background: $black !important;
      box-shadow: none;
      border: 1px solid $black;
      color: $base-yellow !important;
    }
  }

  .sub-btn {
    width: 100px;
    height: 40px;
    border: none;
    box-shadow: none;
    background: transparent;
    /* Button */

    font-family: "Ageo";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;

    /* Black */
    color: #000000;
    &:focus,
    &:hover {
      background: transparent !important;
      /* Black */
      color: #000000 !important;
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.main-return-modal {
  .modal-content {
    max-width: 431px;
    height: 225px;
    border-radius: 10px;
    background: white;
    padding: 32px;
    margin: 0 auto;
  }

  .main-heading {
    /* Info */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    color: #000000;
  }

  p {
    margin-top: 20px;

    margin-bottom: 0 !important;
 
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    /* Grey */
    color: #707581;
  }

  .main-btn {
    width: 160px;
    height: 40px;
    background: $black;
    color: $base-yellow;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    border-radius: 20px;
    border: 1px solid $black;
    cursor: pointer;
    &:focus,
    &:hover {
      background: $black !important;
      box-shadow: none;
      border: 1px solid $black;
      color: $base-yellow !important;
    }
  }

  .sub-btn {
    width: 100px;
    height: 40px;
    border: none;
    box-shadow: none;
    background: transparent;
    /* Button */

    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;

    /* Black */
    color: #000000;
    &:focus,
    &:hover {
      background: transparent !important;
      /* Black */
      color: #000000 !important;
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
