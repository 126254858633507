/* Main Return Order CSS */
.main-return-order {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-top: 20px;
  .orderid-status-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orderid-div {
      display: flex;
      padding-left: 22px;
      align-items: center;
      h5 {
        margin-bottom: 0;
        margin-left: 15px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
    }
  }
  .product-total-div {
    margin-top: 21px;
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    p {
      margin-bottom: 0;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #979797;
    }
  }

  .product-detail-amount {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;

    .order-card-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;
      gap: 10px;

      .order-img-div {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .order-info {
        padding-left: 11px;
        p {
          margin-bottom: 0;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #363636;
          span {
            font-weight: 600;
          }
        }
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
    }
  }

  .main-buttons-div {
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;
    height: 679px;
    overflow: auto;
    .buttons-div {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: end;
      .right-div {
        a {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 48px;
          color: #dbbb51;
          height: 48px;
          width: 200px;
          display: inline-block;
          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
        button {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;

          color: #dbbb51;
          height: 48px;
          width: 200px;

          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          border: none;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
  }
  .radio-button input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;

    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    transition: all 0.3s;
    position: relative;
  }

  .radio-button input[type="checkbox"]:checked:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #dbbb51;
    z-index: 1;
  }

  .radio-button input[type="checkbox"]:checked:after {
    content: "";
    display: block;
    position: absolute;
    top: -6px;
    left: -6px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid #dbbb51;
    z-index: 0;
  }

  .radio-button input[type="checkbox"]:not(:checked):after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid gray;
  }
}

/* Main Return Detail */
.main-return-order-detail {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-top: 20px;
  .orderid-status-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orderid-div {
      display: flex;
      padding-left: 22px;
      align-items: center;
      h5 {
        margin-bottom: 0;
        margin-left: 15px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
    }
  }
  .product-total-div {
    margin-top: 21px;
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    p {
      margin-bottom: 0;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #979797;
    }
  }

  .product-detail-amount {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;

    .order-card-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;
      gap: 10px;

      .order-img-div {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .order-info {
        padding-left: 11px;
        p {
          margin-bottom: 0;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #363636;
          span {
            font-weight: 600;
          }
        }
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
    }
  }

  .main-buttons-div {
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;

    .buttons-div {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: end;
      .right-div {
        a {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 48px;
          color: #dbbb51;
          height: 48px;
          width: 200px;
          display: inline-block;
          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
        button {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;

          color: #dbbb51;
          height: 48px;
          width: 200px;

          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          border: none;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
  }
  form {
    .form-floating {
      label {
        font-family: "Ageo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
        padding: 13px 16px;
        &:focus {
          position: absolute;
          top: -10px !important;
        }
      }

      input {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 25px;

        padding: 13px 16px !important;

        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }

      textarea {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 20px;
        padding: 13px 16px !important;
        height: 100px;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
      select {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 20px;
        padding: 13px 16px !important;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
    }
    .form-group {
      margin-bottom: 20px;
    }
    .form-label {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      margin-bottom: 8px;
      background-color: transparent;
    }
    .form-control {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #3a3b3b;
      background: transparent;
      border: 1px solid #ceced4;
      border-radius: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        display: none;
      }
    }
    .form-select {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      background-color: transparent;
      border: 1px solid #ebebf9;
      border-radius: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      background-image: url("../images/BuyerProfile/drop-down-arrow.png");
      background-size: 11px;
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      display: block;
      width: 100%;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
    }
    .button-div {
      display: flex;
      justify-content: end;
      button {
        margin-top: 10px;
        margin-bottom: 40px;
        background: $black;
        border-radius: 20px;
        height: 48px;
        max-width: 200px;
        width: 100%;
        color: #dbbb51;
        text-transform: capitalize;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
        transition: 0.2s ease-in;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: #dbbb51;
          color: #ffffff;
        }
      }
    }
  }
  .quantity-btn {
    width: 103px;
    height: 48px;
    border: 1px solid #000000;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 0px;
  }
}

/* Main Additional Detail */

.main-additional-detail {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 23px;
  padding-right: 23px;
  h4 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #020203;
    margin-bottom: 0;
    padding-bottom: 9px;
  }

  .main-buttons-div {
    margin-left: 22px;
    padding-bottom: 21px;

    .buttons-div {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: end;
      .right-div {
        a {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 48px;
          color: #dbbb51;
          height: 48px;
          width: 200px;
          display: inline-block;
          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
        button {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;

          color: #dbbb51;
          height: 48px;
          width: 200px;

          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          border: none;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
  }
  form {
    .form-floating {
      label {
        font-family: "Ageo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
        padding: 13px 16px;
        &:focus {
          position: absolute;
          top: -10px !important;
        }
      }

      input {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 25px;

        padding: 13px 16px !important;

        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }

      textarea {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 20px;
        padding: 13px 16px !important;
        height: 100px;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
      select {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 20px;
        padding: 13px 16px !important;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $base-yellow;

          label {
            color: blue !important;
          }
        }

        &:not(:placeholder-shown) ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }

        &:focus ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
    }
    .form-group {
      margin-bottom: 20px;
    }
    .form-label {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      margin-bottom: 8px;
      background-color: transparent;
    }
    .form-control {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #3a3b3b;
      background: transparent;
      border: 1px solid #ceced4;
      border-radius: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        display: none;
      }
    }
    .form-select {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      background-color: transparent;
      border: 1px solid #ebebf9;
      border-radius: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      background-image: url("../images/BuyerProfile/drop-down-arrow.png");
      background-size: 11px;
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      display: block;
      width: 100%;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
    }
    .button-div {
      display: flex;
      justify-content: end;
      button {
        margin-top: 10px;
        margin-bottom: 40px;
        background: $black;
        border-radius: 20px;
        height: 48px;
        max-width: 200px;
        width: 100%;
        color: #dbbb51;
        text-transform: capitalize;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        border: none;
        transition: 0.2s ease-in;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: #dbbb51;
          color: #ffffff;
        }
      }
    }
  }
  .quantity-btn {
    width: 103px;
    height: 48px;
    border: 1px solid #000000;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 0px;
  }

  .image-uploader {
    img {
      height: 110px;
      cursor: pointer;
    }
  }
}

/* Main Return Payment */
.main-order-return-payment {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-top: 20px;
  .orderid-status-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orderid-div {
      display: flex;
      padding-left: 22px;
      align-items: center;
      h5 {
        margin-bottom: 0;
        margin-left: 15px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        span {
          color: $base-yellow;
        }
      }
    }
  }
  .product-total-div {
    margin-top: 21px;
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    p {
      margin-bottom: 0;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #979797;
    }
  }

  .product-detail-amount {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: none;

    .order-card-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 28px;

      .order-img-div {
        width: 52px;
        height: 52px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .order-info {
        padding-left: 11px;
        p {
          margin-bottom: 0;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #363636;
          span {
            font-weight: 600;
          }
        }
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
    }
  }

  .product-sub-total {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;
    padding-top: 87px;

    .sub-total-heading {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }
  }

  .product-shipping-rate {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;

    .shipping-rate-heading {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
      h5 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0038em;
        color: #707581;
      }
    }
  }

  .product-total-amount {
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;

    .total-amount-heading {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }
  }

  .main-buttons-div {
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;
    .buttons-div {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: end;

      .left-div {
        padding-right: 12px;

        a {
          font-family: $primary-fonts;
          border: 1px solid #000000;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 48px;
          color: #000000;
          height: 48px;
          width: 200px;
          display: inline-block;
          text-align: center;
          &:hover {
            border: 1px solid #dbbb51;
            color: #dbbb51;
          }
        }
        button {
          font-family: $primary-fonts;
          border: 1px solid #000000;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          background-color: transparent;
          color: #000000;
          height: 48px;
          width: 200px;
          text-align: center;
          &:hover {
            border: 1px solid #dbbb51;
            color: #dbbb51;
          }
        }
      }
      .right-div {
        a {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 48px;
          color: #dbbb51;
          height: 48px;
          width: 200px;
          display: inline-block;
          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
        button {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;

          color: #dbbb51;
          height: 48px;
          width: 200px;

          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          border: none;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
  }

  .return-shipment {
    padding-left: 23px;

    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 0;
      padding-bottom: 20px;

    }
    .payment-checkbox {
      .form-check-input {
        border: 1px solid #ceced4;
        border-radius: 3px;
        width: 24px;
        height: 24px;
        margin-right: 13px;
        margin-top: 10px;

        &:checked {
          background-color: $base-yellow;
        }
      }
    }
  }
}
