.banner-bg {
  background-image: url("/src/assets/images/Home/Banner.png");
  height: 510px;
  background-size: cover;
  display: flex;
  align-items: center;
}

.shop-container {
  height: 414px;
  border-radius: 24px;
  background-image: url("/src/assets/images/Home/Product\ ADS.png");
  background-size: cover;

  .product-details {
    padding-top: 110px;
    padding-left: 48px;
    h2 {
      /* Handmade decorative teardrops */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: #323252;
      margin: 0;
      width: 346px;
    }

    p {
      /* New arrivals from handmade masters, Ceramic and aluminum in bright yellow */
      width: 346px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #323252;
      margin: 0;
      margin-top: 16px;
      width: 320px;
    }


  }
}

.prev-next-btn {
  background: $white-color;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  &:focus,
  &:hover {
    border: none;
    box-shadow: none!important;
    outline: none;
    background: $white-color!important;
  }
}

.navigation-btns {
  position: absolute;
  right: 24px;
  z-index: 9;
  bottom: 24px;
  width: 88px;
}

.product-card-shop {
  height: 414px;
  border-radius: 24px;
  background: url(/src/assets/images/Home/productimg.png);
  background-repeat: no-repeat;
  padding-top: 110px;
  padding-left: 48px;
  box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);

  h2 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #323252;
    margin: 0;
    width: 222px;
  }
  p {
    /* Handmade table decorations for the upcoming Halloween */
    width: 222px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #323252;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 39px;
  }
}


.shop-btn {
    width: 200px;
    height: 48px;
    background: #f7f7f9;
    box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
    border-radius: 100px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #323252;
    border: none;
    margin-top: 24px;
    &:hover,&:focus{
      background: #f7f7f9!important;
      color: #323252!important;
    }
  }