.swiper {
  width: 100%;
  height: 100%;
  box-shadow: 0px 10px 26px rgba(120, 169, 227, 0.12);
  border-radius: 24px;
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

