.wishlist {
  margin-top: 30px;
  .wishlist-heading {
    /* Items in your wishlist (3) */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $black;
  }

  .cursor-link {
    cursor: pointer;
  }

  .delete-txt {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $delete-txt;
    padding-left: 10px;
  }

  //   .table {

  //     tr{
  //         background:blue!important;
  //     }
  //     --bs-table-striped-bg: white !important;
  //     --bs-table-hover-bg: white !important;
  //     --bs-table-bg: white !important;
  //     border: white;
  //     .form-check-input {
  //       border: 1px solid #ceced4;
  //       border-radius: 3px;
  //       width: 24px;
  //       height: 24px;
  //       margin-top: 20px;
  //       margin-right: 47px;

  //       &:checked {
  //         background-color: $base-yellow;
  //       }
  //     }
  //     label {
  //       width: 90%;
  //     }
  //   }

  .unit-price {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: $black;
    margin-top: 22px;
  }

  .grid-col {
    background: $navbar-bg;
    padding: 15px;
    /* Product name */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    margin-bottom: 16px;
  }

  .product-name {
    /* Wood Ornament */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    padding-left: 25px;
  }

  .no-products-in-wishlist{
    /* No products added to the wishlist */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #77778D;
    margin-top: 40px;
    text-align: center;
  }

  .wishlist-grid {
    height: 330px;
    margin-bottom: 50px;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
      }
      
    .form-check-input {
      border: 1px solid #ceced4;
      border-radius: 3px;
      width: 24px;
      height: 24px;
      margin-top: 20px;
      margin-right: 47px;

      &:checked {
        background-color: $base-yellow;
      }
    }
    label {
      width: auto;
    }
  }
}


