/* Main Buyer Profile CSS */
.main-buyer-profile {
  margin-bottom: 70px;
}

/* Main Buyer Account Details CSS */
.main-buyer-account-details {
  margin-top: 30px;
  background-color: #f6f6fa;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  min-height: 480px;
  padding-bottom: 20px;
  .top-heading-edit-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        font-family: $primary-fonts;
        color: $black;
      }
    }
    .edit-div {
      a {
        font-family: $primary-fonts;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $black;
      }
    }
  }
  .buyer-account-content {
    margin-top: 32px;

    .buyer-avatar {
      width: 108px;
      height: 108px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .buyer-name {
      margin-top: 16px;
      h2 {
        font-family: $primary-fonts;
        color: $black;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
      }
    }
    .buyer-info {
      margin-top: 18px;
      /* padding-bottom: 35px; */
      ul {
        padding: 0;

        li {
          list-style: none;
          margin-bottom: 10px;
          span {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #3a3b3b;
            &:first-child {
              font-family: $primary-fonts;
              margin-right: 20px;
              max-width: 130px;
              width: 100%;
              display: inline-block;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: $black;
            }
          }
        }
      }
    }
  }
}

/* Main Buyer Address CSS */
.main-buyer-address {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  height: 471px;

  .top-heading-edit-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        font-family: $primary-fonts;
        color: $black;
      }
    }
    // .edit-div {
    //   a {
    //     font-family: $primary-fonts;
    //     margin-bottom: 0;
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 16px;
    //     line-height: 19px;
    //     color: $black;
    //   }
    // }
  }
  .buyer-account-content {
    margin-top: 15px;

    .buyer-avatar {
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $light-grey;
      }
    }
    .shipping-addnew-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .shipping-heading {
        margin-top: 16px;
        h2 {
          font-family: $primary-fonts;
          color: $black;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }
      .add-new-div {
        display: flex;
        align-items: center;

        .svg-div {
          width: 13px;
          height: 14px;
          svg {
            display: block;
          }
        }
        .add-new-heading {
          a {
            margin-bottom: 0;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #707581;
            padding-left: 11px;
          }
        }
      }
    }
    .buyer-cards {
      height: 278px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      .buyer-info {
        margin-top: 12px;
        padding: 16px;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        .address-info {
          ul {
            padding: 0;
            margin-bottom: 0;
            li {
              list-style: none;
              width: 400px;
              span {
                font-family: $primary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #3a3b3b;
                &:first-child {
                  font-family: $primary-fonts;
                  margin-right: 20px;
                  max-width: 130px;
                  width: 100%;
                  display: inline-block;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  color: $black;
                }
              }
              &:not(:last-child) {
                margin-bottom: 9px;
              }
            }
          }
        }
        .edit-button-div {
          a {
            margin-bottom: 0;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }
        }
      }
    }
  }
}

/* Main Buyer Privacy */
.main-privacy-section {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  height: 435px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .top-heading-edit-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        font-family: $primary-fonts;
        color: $black;
      }
    }
  }

  .privacy-content-div {
    p {
      margin-bottom: 0;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $black;
      &:first-child {
        margin-top: 15px;
      }
      &:last-child {
        margin-top: 15px;
      }
    }
  }
}

/* Main Buyer Terms */
.main-terms-section {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  height: 435px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .top-heading-edit-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        font-family: $primary-fonts;
        color: $black;
      }
    }
  }

  .terms-content-div {
    p {
      margin-bottom: 0;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $black;
      &:first-child {
        margin-top: 15px;
      }
      &:last-child {
        margin-top: 15px;
      }
    }
  }
}

/* Main Buyer Contact Us */
.main-buyer-contact {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  .top-heading-edit-div {
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        font-family: $primary-fonts;
        color: $black;
      }
      p {
        margin-bottom: 0;
        margin-top: 8px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $btn-text;
      }
    }
  }
  .buyer-contact-fields {
    margin-top: 30px;

    form {
      .form-floating {
        label {
          font-family: "Ageo";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #707581;
          padding: 13px 16px;
          &:focus {
            position: absolute;
            top: -10px !important;
          }
        }

        input {
          width: 100%;
          height: 43px;
          border: 1px solid #ceced4;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 25px;

          padding: 13px 16px !important;

          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $base-yellow;

            label {
              color: blue !important;
            }
          }

          &:not(:placeholder-shown) ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }

          &:focus ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }
        }

        // &:focus {
        //   label {
        //     position: absolute;
        //     top: -10px!important;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     padding: 1rem 0.75rem;
        //     overflow: hidden;
        //     text-align: start;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     pointer-events: none;
        //     border: 1px solid transparent;
        //     transform-origin: 0 0;
        //     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
        //     color: blue !important;
        //   }
        // }
        textarea {
          width: 100%;
          height: 43px;
          border: 1px solid #ceced4;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 20px;
          padding: 13px 16px !important;
          height: 100px;
          resize: none;
          &::-webkit-scrollbar {
            display: none;
          }
          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $base-yellow;

            label {
              color: blue !important;
            }
          }

          &:not(:placeholder-shown) ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }

          &:focus ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }
        }
        select {
          width: 100%;
          height: 43px;
          border: 1px solid #ceced4;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 20px;
          padding: 13px 16px !important;
          resize: none;
          &::-webkit-scrollbar {
            display: none;
          }
          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $base-yellow;

            label {
              color: blue !important;
            }
          }

          &:not(:placeholder-shown) ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }

          &:focus ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }
        }
      }
      .form-group {
        margin-bottom: 20px;
      }
      .form-label {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black;
        margin-bottom: 8px;
        background-color: transparent;
      }
      .form-control {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #3a3b3b;
        background: transparent;
        border: 1px solid #ceced4;
        border-radius: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;
        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          display: none;
        }
      }
      .form-select {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: 1px solid #ebebf9;
        border-radius: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;
        background-image: url("../images/BuyerProfile/drop-down-arrow.png");
        background-size: 11px;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        display: block;
        width: 100%;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
        cursor: pointer;
        &:focus {
          box-shadow: none;
        }
      }
      .button-div {
        display: flex;
        justify-content: end;
        button {
          margin-top: 10px;
          margin-bottom: 40px;
          background: $black;
          border-radius: 20px;
          height: 48px;
          max-width: 200px;
          width: 100%;
          color: #dbbb51;
          text-transform: capitalize;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border: none;
          transition: 0.2s ease-in;
          &:focus {
            box-shadow: none;
          }
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
          }
        }
      }
    }
  }
}

/* Main Buyer Change Password */
.main-buyer-changepassword {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  .top-heading-edit-div {
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        font-family: $primary-fonts;
        color: $black;
      }
      p {
        margin-bottom: 0;
        margin-top: 8px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $btn-text;
      }
    }
  }
  .buyer-contact-fields {
    margin-top: 25px;

    form {
      .form-floating {
        label {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #707581;
          padding: 13px 16px;
          &:focus {
            position: absolute;
            top: -10px !important;
          }
        }

        input {
          width: 100%;
          height: 43px;
          border: 1px solid #ceced4;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-family: "Ageo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 25px;

          padding: 13px 16px !important;
          position: relative;
          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $base-yellow;

            label {
              color: blue !important;
            }
          }

          &:not(:placeholder-shown) ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }

          &:focus ~ label {
            background: #f6f6fa;
            padding: 0;
            margin: 0;
            top: 0;
            opacity: 1;
            display: inline;
            width: auto;
            height: auto;
            left: 12px;
            padding: 0 8px;
          }
        }

        // &:focus {
        //   label {
        //     position: absolute;
        //     top: -10px!important;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     padding: 1rem 0.75rem;
        //     overflow: hidden;
        //     text-align: start;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     pointer-events: none;
        //     border: 1px solid transparent;
        //     transform-origin: 0 0;
        //     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
        //     color: blue !important;
        //   }
        // }
      }
      .form-group {
        margin-bottom: 20px;
      }
      .form-label {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black;
        margin-bottom: 8px;
        background-color: transparent;
      }
      .form-control {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #3a3b3b;
        background: transparent;
        border: 1px solid #ceced4;
        border-radius: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          display: none;
        }
      }
      .form-select {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        background: #ffffff;
        border: 1px solid #ebebf9;
        border-radius: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;
        background-image: url("../images/BuyerProfile/drop-down-arrow.png");
        background-size: 11px;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        display: block;
        width: 100%;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
        cursor: pointer;
        &:focus {
          box-shadow: none;
        }
      }
      .button-div {
        display: flex;
        justify-content: end;
        button {
          margin-top: 10px;
          margin-bottom: 40px;
          background: $black;
          border-radius: 20px;
          height: 48px;
          max-width: 200px;
          width: 100%;
          color: #dbbb51;
          text-transform: capitalize;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border: none;
          transition: 0.2s ease-in;
          &:focus {
            box-shadow: none;
          }
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
          }
        }
      }
    }
  }
}

/* Main Buyer Account Details Edit CSS */
.main-buyer-account-details-edit {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  .top-heading-edit-div {
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        font-family: $primary-fonts;
        color: $black;
      }
    }
  }
  .buyer-account-content {
    margin-top: 32px;
    position: relative;
    .buyer-avatar {
      width: 108px;
      height: 108px;
      width: 108px;
      height: 108px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .camera-div {
      position: absolute;
      top: 78px;
      /* bottom: 0; */
      left: 70px;
      /* bottom: 55px; */
      width: 32px;
      background-color: #fdfdff;
      height: 32px;
      border-radius: 50%;
      text-align: center;
      border: 2px solid #f6f6fa;
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
    .buyer-name {
      margin-top: 16px;
      h2 {
        font-family: $primary-fonts;
        color: $black;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
      }
    }
    .buyer-info {
      margin-top: 20px;
      padding-bottom: 35px;
      ul {
        padding: 0;

        li {
          list-style: none;
          margin-bottom: 15px;
          span {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #3a3b3b;
            &:first-child {
              font-family: $primary-fonts;
              margin-right: 20px;
              max-width: 130px;
              width: 100%;
              display: inline-block;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: $black;
            }
          }
        }
      }
    }
    .buyer-contact-fields {
      margin-top: 20px;
      form {
        .form-floating {
          label {
            font-family: "Ageo";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #707581;
            padding: 13px 16px;
            &:focus {
              position: absolute;
              top: -10px !important;
            }
          }

          input {
            width: 100%;
            height: 43px;
            border: 1px solid #ceced4;
            border-radius: 20px;
            display: flex;
            align-items: center;
            font-family: "Ageo";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-bottom: 30px;

            padding: 13px 16px !important;

            &:focus {
              outline: none;
              box-shadow: none;
              border: 1px solid $base-yellow;

              label {
                color: blue !important;
              }
            }

            &:not(:placeholder-shown) ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }

            &:focus ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }
          }

          // &:focus {
          //   label {
          //     position: absolute;
          //     top: -10px!important;
          //     left: 0;
          //     width: 100%;
          //     height: 100%;
          //     padding: 1rem 0.75rem;
          //     overflow: hidden;
          //     text-align: start;
          //     text-overflow: ellipsis;
          //     white-space: nowrap;
          //     pointer-events: none;
          //     border: 1px solid transparent;
          //     transform-origin: 0 0;
          //     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
          //     color: blue !important;
          //   }
          // }
          select {
            width: 100%;
            height: 43px;
            border: 1px solid #ceced4;
            border-radius: 20px;
            display: flex;
            align-items: center;
            font-family: "Ageo";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-bottom: 30px;
            padding: 13px 16px !important;
            resize: none;
            &::-webkit-scrollbar {
              display: none;
            }
            &:focus {
              outline: none;
              box-shadow: none;
              border: 1px solid $base-yellow;

              label {
                color: blue !important;
              }
            }

            &:not(:placeholder-shown) ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }

            &:focus ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }
          }
        }
        .form-group {
          margin-bottom: 20px;
        }
        .form-label {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $black;
          margin-bottom: 8px;
          background-color: transparent;
        }
        .form-control {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #3a3b3b;
          background: transparent;
          border: 1px solid #ceced4;
          border-radius: 16px;
          padding-top: 14px;
          padding-bottom: 14px;
          padding-left: 20px;
          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            display: none;
          }
        }
        .form-select {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          background: transparent;
          border: 1px solid #ebebf9;
          border-radius: 16px;
          padding-top: 14px;
          padding-bottom: 14px;
          padding-left: 20px;
          background-image: url("../images/BuyerProfile/drop-down-arrow.png");
          background-size: 11px;
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          display: block;
          width: 100%;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          appearance: none;
          cursor: pointer;
          &:focus {
            box-shadow: none;
          }
        }
        .button-div {
          display: flex;
          justify-content: end;
          button {
            margin-top: 10px;
            margin-bottom: 40px;
            background: $black;
            border-radius: 20px;
            height: 48px;
            max-width: 200px;
            width: 100%;
            color: #dbbb51;
            text-transform: capitalize;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            border: none;
            transition: 0.2s ease-in;
            &:focus {
              box-shadow: none;
            }
            &:hover {
              background-color: #dbbb51;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

/* Main Buyer Address Edit CSS */
.main-buyer-address-edit {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-left: 33px;
  padding-right: 31px;
  .top-heading-edit-div {
    padding-top: 30px;
    .heading-div {
      h4 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        font-family: $primary-fonts;
        color: $black;
      }
    }
  }
  .buyer-account-content {
    margin-top: 32px;
    position: relative;
    .buyer-avatar {
      width: 108px;
      height: 108px;
      width: 108px;
      height: 108px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .camera-div {
      position: absolute;
      top: 78px;
      left: 70px;
      width: 32px;
      background-color: #fdfdff;
      height: 32px;
      border-radius: 50%;
      text-align: center;
      border: 2px solid #f6f6fa;
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
    .buyer-name {
      margin-top: 16px;
      h2 {
        font-family: $primary-fonts;
        color: $black;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
      }
    }
    .buyer-info {
      margin-top: 20px;
      padding-bottom: 35px;
      ul {
        padding: 0;

        li {
          list-style: none;
          margin-bottom: 15px;
          span {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #3a3b3b;
            &:first-child {
              font-family: $primary-fonts;
              margin-right: 20px;
              max-width: 130px;
              width: 100%;
              display: inline-block;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: $black;
            }
          }
        }
      }
    }
    .buyer-contact-fields {
      margin-top: 20px;
      form {
        .form-floating {
          label {
            font-family: "Ageo";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #707581;
            padding: 13px 16px;
            &:focus {
              position: absolute;
              top: -10px !important;
            }
          }

          input {
            width: 100%;
            height: 43px;
            border: 1px solid #ceced4;
            border-radius: 20px;
            display: flex;
            align-items: center;
            font-family: "Ageo";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-bottom: 30px;

            padding: 13px 16px !important;

            &:focus {
              outline: none;
              box-shadow: none;
              border: 1px solid $base-yellow;

              label {
                color: blue !important;
              }
            }

            &:not(:placeholder-shown) ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }

            &:focus ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }
          }

          // &:focus {
          //   label {
          //     position: absolute;
          //     top: -10px!important;
          //     left: 0;
          //     width: 100%;
          //     height: 100%;
          //     padding: 1rem 0.75rem;
          //     overflow: hidden;
          //     text-align: start;
          //     text-overflow: ellipsis;
          //     white-space: nowrap;
          //     pointer-events: none;
          //     border: 1px solid transparent;
          //     transform-origin: 0 0;
          //     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
          //     color: blue !important;
          //   }
          // }
          select {
            width: 100%;
            height: 43px;
            border: 1px solid #ceced4;
            border-radius: 20px;
            display: flex;
            align-items: center;
            font-family: "Ageo";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-bottom: 30px;
            padding: 13px 16px !important;
            resize: none;
            &::-webkit-scrollbar {
              display: none;
            }
            &:focus {
              outline: none;
              box-shadow: none;
              border: 1px solid $base-yellow;

              label {
                color: blue !important;
              }
            }

            &:not(:placeholder-shown) ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }

            &:focus ~ label {
              background: #f6f6fa;
              padding: 0;
              margin: 0;
              top: 0;
              opacity: 1;
              display: inline;
              width: auto;
              height: auto;
              left: 12px;
              padding: 0 8px;
            }
          }
        }
        .form-group {
          margin-bottom: 20px;
        }
        .form-label {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $black;
          margin-bottom: 8px;
          background-color: transparent;
        }
        .form-control {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #3a3b3b;
          background: transparent;
          border: 1px solid #ceced4;
          border-radius: 16px;
          padding-top: 14px;
          padding-bottom: 14px;
          padding-left: 20px;
          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            display: none;
          }
        }
        .form-select {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          background: transparent;
          border: 1px solid #ebebf9;
          border-radius: 16px;
          padding-top: 14px;
          padding-bottom: 14px;
          padding-left: 20px;
          background-image: url("../images/BuyerProfile/drop-down-arrow.png");
          background-size: 11px;
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          display: block;
          width: 100%;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          appearance: none;
          cursor: pointer;
          &:focus {
            box-shadow: none;
          }
        }
        .button-div {
          display: flex;
          justify-content: end;
          button {
            margin-top: 10px;
            margin-bottom: 40px;
            background: $black;
            border-radius: 20px;
            height: 48px;
            max-width: 200px;
            width: 100%;
            color: #dbbb51;
            text-transform: capitalize;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            border: none;
            transition: 0.2s ease-in;
            &:focus {
              box-shadow: none;
            }
            &:hover {
              background-color: #dbbb51;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

/* Main Buyer My Orders CSS */
.main-my-orders {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-right: 20px;
  .tab-buttons-section {
    padding-top: 12px;
    margin-left: 12px;
    li {
      line-height: 17px;
      height: 33px;
      width: 103px;

      &:not(:first-child) {
        margin-left: 18px;
      }
      .nav-link {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        border-radius: 8px;
        background: #ffffff;
        color: #000000;
        width: 100%;
        &.active {
          border: 1.5px solid #dbbb51;
        }
      }
    }
  }
  .tab-content {
    margin-top: 28px;

    .tab-pane {
      .main-order-count {
        margin-bottom: 22px;
        display: inline-block;
        span {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #979797;
        }
      }
      .order-card-wrapper {
        border-top: 1px solid rgba(112, 117, 129, 0.1);
        .orderid-view-details {
          display: flex;
          justify-content: space-between;
          padding-top: 26px;

          .order-date {
            p {
              margin-bottom: 0;
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: $black;
              span {
                color: $colorgray;
              }
              &:not(:first-child) {
                padding-top: 6px;
              }
            }
          }
          .view-details {
            a {
              margin-bottom: 0;
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              color: $colorgray;
            }
          }
        }

        .order-details {
          margin-top: 21px;
          display: flex;

          .order-img-div {
            width: 66px;
            height: 66px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .order-content {
            margin-left: 16px;
            h5 {
              margin-bottom: 0;
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: $black;
            }
            span {
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              color: $base-gray;
            }
            p {
              margin-bottom: 0;
            }
          }
        }

        .quantity-total-amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 22px;
          padding-bottom: 21px;

          p {
            margin-bottom: 0;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #979797;
          }
          h5 {
            margin-bottom: 0;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            span {
              font-weight: 600;
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}

/* Main Buyer Order Detail CSS */
.main-order-details {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-top: 20px;
  .orderid-status-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orderid-div {
      display: flex;
      padding-left: 22px;
      align-items: center;
      h5 {
        margin-bottom: 0;
        margin-left: 15px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        span {
          color: $base-yellow;
        }
      }
    }
    .status-div {
      padding-right: 25px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 13px;
        color: #09acfe;
        text-transform: capitalize;
      }
    }
  }
  .product-total-div {
    margin-top: 21px;
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    p {
      margin-bottom: 0;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #979797;
    }
  }

  .product-detail-amount {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;

    .order-card-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .order-img-div {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .order-info {
        padding-left: 11px;
        p {
          margin-bottom: 0;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #363636;
          span {
            font-weight: 600;
          }
        }
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
    }
  }

  .product-sub-total {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;

    .sub-total-heading {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }
  }

  .product-vat {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;

    .vat-heading {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }
  }

  .product-shipping-rate {
    margin-left: 22px;
    margin-right: 21px;
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    padding-bottom: 21px;

    .shipping-rate-heading {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
      }
      h5 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0038em;
        color: #707581;
      }
    }
  }

  .product-total-amount {
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;

    .total-amount-heading {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }

    .amount-div {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }
  }

  .main-cancel-reason {
    margin-left: 22px;
    margin-right: 21px;

    padding-bottom: 21px;

    .cancel-reason-div {
      margin-top: 20px;
      h5 {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0038em;
        color: #000000;
      }
      p {
        margin-bottom: 0;
        padding-top: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        font-family: $primary-fonts;
        display: flex;
        align-items: center;
        letter-spacing: 0.0038em;
        color: #707581;
      }
    }
  }

  .main-buttons-div {
    margin-left: 22px;
    margin-right: 21px;
    padding-bottom: 21px;
    .buttons-div {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: end;

      .left-div {
        padding-right: 12px;

        a {
          font-family: $primary-fonts;
          border: 1px solid #000000;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 48px;
          color: #000000;
          height: 48px;
          width: 200px;
          display: inline-block;
          text-align: center;
          &:hover {
            border: 1px solid #dbbb51;
            color: #dbbb51;
          }
        }
        button {
          font-family: $primary-fonts;
          border: 1px solid #000000;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          background-color: transparent;
          color: #000000;
          height: 48px;
          width: 200px;
          text-align: center;
          &:hover {
            border: 1px solid #dbbb51;
            color: #dbbb51;
          }
        }
      }
      .right-div {
        a {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 48px;
          color: #dbbb51;
          height: 48px;
          width: 200px;
          display: inline-block;
          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
        button {
          font-family: $primary-fonts;
          border-radius: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;

          color: #dbbb51;
          height: 48px;
          width: 200px;

          background-color: #000000;
          text-align: center;
          transition: 0.2s ease-in;
          border: none;
          &:hover {
            background-color: #dbbb51;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
/* Main Transaction History */
.main-transaction-history {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-top: 20px;
  height: 604px;

  .heading-search-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading-div {
      padding-left: 30px;
      h5 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #020203;
      }
    }
    .svg-div {
      button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-right: 28px;
        box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
        width: 50px;
        height: 50px;
        border-radius: 14px;
        background-color: #ffffff;
        text-align: center;
        line-height: 50px;
        svg {
        }
      }
      .searchbar-div {
        .input-group {
          height: 50px;

          .input-group-text {
            background-color: white;
            border: 0;
          }
        }
        .input-group
          > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
            .valid-feedback
          ):not(.invalid-tooltip):not(.invalid-feedback) {
          margin-left: -4px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .cross-icon {
          cursor: pointer;
          .input-group-text {
            background-color: white;
            border: 0;
            margin-left: -5px;
          }
        }
        input {
          border: none;
          margin-left: -2px;
          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }
  .main-order-view {
    padding-left: 31px;
    margin-top: 8px;
    padding-right: 28px;
    height: 480px;

    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .top-header {
      display: flex;
      justify-content: space-between;
      width: 644px;
      padding-bottom: 21px;
      h5 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #979797;
        margin-bottom: 0;
      }
    }

    table {
      thead {
        border-bottom: transparent;
        th {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #979797;
          margin-bottom: 0;
          padding-left: 0;
        }
      }
      tbody {
        tr {
          border-bottom: transparent;
          td {
            padding-left: 0;
            vertical-align: middle;
            text-align: center;
            width: 20%;
            &:last-child {
              padding-right: 0;
              text-align: end;
            }
          }
        }
      }
    }
    .img-id-div {
      display: flex;
      align-items: center;
      .img-div {
        width: 40px;
        height: 40px;
        margin-bottom: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .order-id {
        padding-left: 11px;
        p {
          margin-bottom: 0;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #363636;
        }
      }
    }
    .date-time-div {
      span {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
    }
    .status-div {
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 13px;
        color: #069952;
      }
    }
    .total-amount-div {
      p {
        margin-bottom: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
    }
    .view-details-div {
      a {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #707581;
      }
    }
  }
}

/* Main Transaction History */
.main-transaction-detail {
  margin-top: 30px;
  background-color: $footer-gray;
  border-radius: 16px;
  padding-top: 20px;
  min-height: 475px;
  .orderid-download-div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .orderid-div {
      display: flex;
      padding-left: 22px;
      align-items: center;
      h5 {
        margin-bottom: 0;
        margin-left: 15px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        span {
          color: $base-yellow;
        }
      }
    }
    .download-div {
      padding-right: 25px;
      button {
        border: none;
        background: transparent;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 39px;
        text-transform: capitalize;
        color: #000000;
        border: 1px solid #000000;
        border-radius: 20px;
        width: 141px;
        height: 39px;
        display: block;
        text-align: center;
        transition: 0.1s ease-in;
        &:hover {
          border: 1px solid #dbbb51;
          color: #dbbb51;
          svg {
            path {
              fill: #dbbb51;
              stroke: #dbbb51;
            }
          }
        }
      }
      // a {
      //   font-family: $primary-fonts;
      //   font-style: normal;
      //   font-weight: 700;
      //   font-size: 16px;
      //   line-height: 39px;
      //   text-transform: capitalize;
      //   color: #000000;
      //   border: 1px solid #000000;
      //   border-radius: 20px;
      //   width: 141px;
      //   height: 39px;
      //   display: block;
      //   text-align: center;
      //   transition: 0.1s ease-in;
      //   &:hover {
      //     border: 1px solid #dbbb51;
      //     color: #dbbb51;
      //     svg {
      //       path {
      //         fill: #dbbb51;
      //         stroke: #dbbb51;
      //       }
      //     }
      //   }
      // }
    }
  }
  .border-bottomm {
    border-bottom: 1px solid rgba(112, 117, 129, 0.1);
    margin-left: 25px;
    margin-right: 25px;
    padding-top: 20px;
  }
  .order-detail-div {
    margin-top: 20px;
    ul {
      margin-bottom: 0;
      li {
        list-style: none;
        display: flex;
        padding-bottom: 15px;
        span {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;

          &:first-child {
            width: 170px;
            display: block;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #707581;
          }
        }
        .master-div {
          span {
            font-family: "Ageo";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.078px;

            color: #7c7c7e;
            &:first-child {
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #000000;
              padding-bottom: 2px;
            }
          }
        }
      }
    }
  }
}

.transaction-red-color {
  color: #c6000f !important;
  font-family: $primary-fonts;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-transform: capitalize;
}

.transaction-green-color {
  color: #069952 !important;
  font-family: $primary-fonts;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-transform: capitalize;
}

.img-div {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 24px;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
  }

  .file-upload {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-transaction-history .heading-search-div {
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  .main-transaction-history .heading-search-div .svg-div button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 28px;
    margin-top: 10px;
    // box-shadow: 0px 6px 58px rgb(196 203 214 / 10%);
    width: 50px;
    height: 50px;
    border-radius: 14px;
    background-color: #ffffff;
    text-align: center;
    line-height: 50px;
  }
  .searchbar-div {
    margin-left: 28px;
    margin-top: 10px;
  }
  .main-transaction-history .heading-search-div .svg-div .searchbar-div input {

    width: 140px;
}
}
