.noproduct-text {
  /* There are not products here yet. */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #707581;
  margin: 16px 0;
}

.add-product-btn {
  width: 160px;
  height: 40px;
  background: $black;
  color: $base-yellow;
  box-shadow: none;
  outline: none;
  border: 1px solid $black;
  border-radius: 20px;

  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #dbbb51;


  &:hover,
  &:focus {
    background: rgb(78, 78, 78) !important;
    color: $base-yellow!important;
    outline: none;
    box-shadow: none;
    border: 1px solid $base-yellow;

  }
}
