.rating-and-review-section {
  background: #ffffff;
  border-radius: 14px;
  margin-top: 20px;
  padding: 20px;

  .rating-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: black;
    margin-bottom: 8px;
  }
  .star-reviews {
    /* 4.9 (2130 reviews) */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    padding-left: 10px;
    margin-top: 7px;
  }

  hr {
    height: 1px;
    /* Light Grey-Separator */
    color: #adadad;
    margin: 0;
    margin-top: 20px;
    width: 90%;
  }
}

.review-card {
    margin-top: 20px;
  .review-author {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.38px;
    color: $black;
  }

  .review-date {
    font-family: "Ageo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $colorgray;
    padding-left: 10px;
  }

  .rating {
    /* 4.0 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $black;
    padding-left: 4px;
  }

  .review {
    /* Reviews */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $colorgray;
    max-width: 654px;
    margin-top: 10px;
  }


  .review-card-details{
    padding-left: 11px;
  }
}
