.category-btn-offcanvas {
  width: 504px !important;
  background: #f6f6fa !important;
  padding: 32px 54px 32px 54px;
  // margin-top: 238px;
  // position: absolute;
  // top: 238px!important;
  // height: 100vh;

  ::-webkit-scrollbar {
    display: none;
  }
  

  .category-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #323252;
  }

  .boxes-section{
    margin-top: 45px;
    .category-boxes {
        background: #fdfdff;
        border: 1px solid #e3e3f1;
        border-radius: 14px;
        /* Daily Deals */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #020203;
        display: flex;
        align-items: center;
        padding-left: 16px;
        width: 368px;
        height: 48px;
        margin-bottom: 16px;
        cursor: pointer;
      }
  }



  .cross-btn {
    background: #77778d;
  }
}

.offcanvas-backdrop {
  // position: absolute;
  // top: 238px!important;
  // margin-top: 238px;
}
